import axios from "axios"
import { APICallWithCache, bbProcessEnv } from "../../helpers"

export default {
  getWizardSteps: async () => {
    try {
      const response = await axios.get(
        `${bbProcessEnv.API_URL}/api/v1/lead_workflow`
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  getPricing: async (rateflow_id, formData) => {
    let url = `${bbProcessEnv.API_URL}/rateflow/price.json`
    const params = {}
    if (rateflow_id) {
      params.id = rateflow_id
    }

    try {
      const response = await APICallWithCache({
        url,
        params: {
          ...formData,
          ...params
        },
        method: "post",
        cacheEnabled: bbProcessEnv.PRICING_CACHE_ENABLE
      })

      if (response?.status === "error" || response.data?.status === "error") {
        console.error("Error", response?.message ?? response?.data?.message)
        return []
      }

      return response
    } catch (error) {
      console.log(error)
    }
  },
  getFeesPdf: async params => {
    if (!params.quote_id) {
      return null
    }
    try {
      const response = await axios.get(
        `${bbProcessEnv.API_URL}/api/v1/loan-summary-pdf`,
        { params }
      )

      const url = response.data.file
      const name = response.data.label
      await axios({
        url: url,
        method: "GET",
        responseType: "blob" // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", name + ".pdf")
        document.body.appendChild(link)
        link.click()
      })
    } catch (error) {
      console.log(error)
    }
  }
}
