import axios from "axios"
import * as Sentry from "@sentry/browser"
import { showBlockingModal } from "../../message-bridge"
import { getConfig } from "../../config"

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const widgetConfig = getConfig()
    config.params = config.params || {}

    if (typeof bb_sid !== "undefined") {
      config.params.bb_sid = bb_sid
    } else {
      if (widgetConfig.app_key || config.params.app_key) {
        config.params.app_key = widgetConfig.app_key || config.params.app_key
      }
      if (widgetConfig.bbToken || config.params.bbToken) {
        config.params.bbToken = widgetConfig.bbToken || config.params.bbToken
      }
    }

    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    const { status, message } = response.data
    if (
      status === "error" &&
      (message === "not authorized" || message === "not allowed to do that")
    ) {
      console.error(`${status}: ${message}`)
      showBlockingModal()
    } else if (
      status === "error" &&
      message !== "quote failed - no valid quotes" &&
      message !== "quote failed - disabled" // Avoid errors related to no quotes
    ) {
      Sentry?.captureException(response.data)
    }

    return response
  },
  function (error) {
    console.error(error)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)
