/**
 * Redux store
 */
import { createStore, applyMiddleware, combineReducers } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import {
  rootReducer,
  wizardReducer,
  rateTableReducer,
  subscriptionDashReducer,
  mortgageCalcReducer
} from "../reducers"
import { middlewares } from "../middleware"
import createSagaMiddleware from "redux-saga"
import rootSaga from "../sagas"
import { EmbedConfig, RootReducers } from "../types"

const initialiseSagaMiddleware = createSagaMiddleware()

const storeEnhancers = composeWithDevTools({ trace: true })

let store: any

type InitStore = {
  hasWizard?: boolean
  hasRateTable?: boolean
  hasMortgageCalc?: boolean

  isSubscriptionDash?: boolean
  config?: EmbedConfig
}

const initStore = ({
  hasWizard,
  hasRateTable,
  hasMortgageCalc,
  isSubscriptionDash,
  config
}: InitStore) => {
  const reducers: RootReducers = { app: rootReducer }
  if (hasWizard) {
    reducers.wizard = wizardReducer
  }
  if (hasRateTable) {
    reducers.rateTable = rateTableReducer
  }
  if (hasMortgageCalc) {
    reducers.mortgageCalc = mortgageCalcReducer
  }
  if (isSubscriptionDash) {
    reducers.subscriptionDash = subscriptionDashReducer
  }

  const combinedReducers = combineReducers(reducers)

  store = createStore(
    combinedReducers,
    { app: config as any },
    storeEnhancers(applyMiddleware(middlewares, initialiseSagaMiddleware))
  )

  initialiseSagaMiddleware.run(rootSaga)

  return store
}

export { store, initStore }
