import axios, { AxiosResponse } from "axios"
import { getConfig } from "../config"
import { getStorage, setStorage } from "./localStorageMngr"

interface CachedResponseBase {
  url: string
  params: any
}

interface CachedResponse extends CachedResponseBase {
  data: any
  timestamp: number
}

interface CacheAPIResponseParams {
  url: string
  params?: any
  method?: "get" | "post"
  cacheEnabled?: boolean
  expires?: number
}

interface CacheAPIResponse {
  (params: CacheAPIResponseParams): Promise<any>
}

const storageKey = "cachedResponses"

const getCacheFromLocalStorage = (suffix: string): CachedResponse[] => {
  const { cachedResponses }: { cachedResponses: CachedResponse[] } = getStorage(
    `${storageKey}-${suffix}`
  )

  if (cachedResponses?.[0]) {
    return cachedResponses
  }
  return []
}

const setCacheToLocalStorage = (
  cachedResponses: CachedResponse[],
  suffix: string
): void => {
  setStorage({ cachedResponses }, `${storageKey}-${suffix}`)
}

const setRequestParams = (params: any, method: "get" | "post") => {
  // params is optional, so we need to check if it exists before passing it. Also, if exist and its a "get" method we need to wrap it in an object with the key params
  return params ? (method === "get" ? { params } : params) : {}
}

const APICallWithCache: CacheAPIResponse = async ({
  url,
  params,
  method = "get",
  cacheEnabled = false,
  expires = 3600000 // 1 hour in milliseconds
}) => {
  let suffix = ""
  if (cacheEnabled) {
    let cachedResponses
    const { app_key } = getConfig()

    // if it's dashboard we use bb_sid, otherwise we use app_key
    if (typeof bb_sid !== "undefined") {
      // only use the last 8 characters of the bb_sid
      suffix = bb_sid.slice(-8)
    } else {
      suffix = app_key
    }

    cachedResponses = getCacheFromLocalStorage(suffix)
    const cachedResponse = cachedResponses.find(
      response => response.url === url && compareParams(response.params, params)
    )

    if (cachedResponse) {
      if (Date.now() - cachedResponse.timestamp < expires) {
        // add one second delay to simulate network latency
        return new Promise(resolve =>
          setTimeout(() => resolve(cachedResponse.data), 1000)
        )
      } else {
        removeExpiredCachedResponses(cachedResponses, suffix, url, params)
      }
    }
  }

  const requestParams = setRequestParams(params, method)

  const response: AxiosResponse = await axios[method](url, requestParams)
  const data = response.data

  // if cache is enabled, we cache the response
  if (cacheEnabled) {
    saveNewResponseToCache(
      {
        url,
        params,
        data,
        timestamp: Date.now()
      },
      suffix
    )
  }

  return data
}

const removeExpiredCachedResponses = (
  cachedResponses: CachedResponse[],
  suffix: string,
  url: string,
  params: any
): void => {
  // if the cached response is expired, we remove it from the cache
  cachedResponses = cachedResponses.filter(
    response => response.url !== url || !compareParams(response.params, params)
  )
  setCacheToLocalStorage([...cachedResponses], suffix)
}

const saveNewResponseToCache = (
  newCachedResponse: CachedResponse,
  suffix: string
): void => {
  const cachedResponses = getCacheFromLocalStorage(suffix)

  setCacheToLocalStorage([...cachedResponses, newCachedResponse], suffix)
}

/**
 *  While this kind of comparison is not ensure 100% accuracy, it should be good enough for our purposes
 * */
const compareParams = (paramsOld: any, paramsNew: any): boolean => {
  return JSON.stringify(paramsOld) === JSON.stringify(paramsNew)
}

export default APICallWithCache
