const leadWorkflowInitialFormState = {
  currentStep: 0,
  values: {
    code: "",
    login_form: { form: {} },
    steps: {}
  },
  card_details: null,
  validateError: false,
  validation: {}
}

const leadWorkflowInitialState = {
  stepsLoaded: false,
  pricingNotLoadedBeforeLead: false,
  pricingGridLoaded: false,
  steps: [],
  pricingGridIsLoading: false,
  isLoading: true,
  isLoadingLastStep: false,
  branded: false,
  subscribed: false,
  subscribeEnabled: false,
  disclaimers: {},
  phoneRequired: false,
  validationRequired: false,
  validationReady: false,
  disable_pricing: false,
  loanTermChanged: true,
  ...leadWorkflowInitialFormState
}

const leadWorkEndStepsData = [
  {
    id: "login_form",
    type: "form",
    title:
      "Enter your contact info to receive your verification code via text or email.",
    fields: [
      {
        id: "first_name",
        placeholder: "First Name",
        type: "text"
      },
      {
        id: "last_name",
        placeholder: "Last Name",
        type: "text"
      },
      {
        id: "phone",
        placeholder: "Enter valid phone",
        type: "tel"
      },
      {
        id: "email",
        placeholder: "Enter valid email",
        type: "email"
      },
      {
        id: "verification_type",
        type: "checkbok",
        options: [{ id: "sms", label: "Send my verification code via SMS" }]
      }
    ],
    submitLabel: "Unlock & view my rates"
  },
  {
    id: "code",
    title: "",
    offer: "250",
    type: "form_verification",
    submitLabel: "View My Results",
    fields: [
      {
        id: "code",
        placeholder: "4 Digit Verification Number",
        type: "text"
      }
    ]
  },
  {
    type: "end",
    id: "end"
  }
]

export {
  leadWorkflowInitialState,
  leadWorkflowInitialFormState,
  leadWorkEndStepsData
}
