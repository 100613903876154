import axios from "axios"
import { bbProcessEnv } from "../../helpers"

export default {
  get: async () => {
    try {
      const response = await axios.get(
        `${bbProcessEnv.API_URL}/api/v1/location`
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  coordsToZip: async ({ lat, lng }: { lat: number; lng: number }) => {
    try {
      const response = await axios.get(
        `${bbProcessEnv.API_URL}/api/v1/coords-to-zip?lat=${lat}&lng=${lng}`
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
}
